/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

body, .body {
  font-family: "BrixSansRegular", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  /*margin-bottom: 30px !important;*/
}


[type='radio'] {
  display: none;
}


.search-separator {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
}

  .search-separator::before,
  .search-separator::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }

  /*
.btn {
  font-weight: 400 !important;
  touch-action: manipulation !important;
}*/

h2, .h2 {
  font-family: "BrixSansRegular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 30px !important;
  letter-spacing: .5px !important;
}

h1, .h1 {
  font-family: "BrixSansBold","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.btn.btn-navigation {
  color: #2a6992;
  background: #fff;
  border-color: #2a6992;
  border: 2px solid;
  text-transform: uppercase;
}

.btn-navigation:hover {
  color: #fff !important;
  background: #004268;
}

.btn.btn-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #00629B;
  font-family: "BrixSansRegular","Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 1rem;
  border: 1px solid white;
}

.btn.btn-selection:hover {
  background: #004268;
  color: #fff !important;
}

.btn.active {
  /* TODO: Figure out why :active psuedo class is not working */
  background: #004268;
}


.ng-select-container {
  height: 55px !important;
}

.form-control {
  height: 55px !important;
}

button.btn.btn-navigation.btn-search {
  height: 55px;
}


.title-link {
  float: left;
  clear: left;
  text-decoration: none;
  letter-spacing: -1px;
  white-space: nowrap;
}

a.title-link:hover {
  text-decoration: none;
  background: transparent;
  color: #00629B;
  outline: none;
}


/* Colors*/
.blue {
  color: #00629B;
}

.gray {
  color: #747678;
}

.bg-blue {
  background-color: #00629B !important;
}


.emphasized-text {
  font-weight: bold;
  color: #0055a6;
  text-transform: uppercase;
}

.less-emphasized-text {
  color: #545765;
  font-weight: bold;
}

.wrap-navbar {

}


#navLogo {
  /* TODO: Why isn't this working when deployed??'*/
  width: 150px;
  height: 55px;
  display: block;
  /*background: url("/Images/UCSD_logo.png") no-repeat;*/
  font-size: 20px;
}
.wrap-header {
  box-shadow: 0 2px 4px 1px rgba(0,0,0,.2);
  border-bottom: #d6d6d6 solid 1px;
  height: 145px;
}

.top-border {
  height: 10px;
  width: 100%;
}

.wrap-logo {
  float: left;
  margin: 17px 0 14px 0;
}


.dropdown-menu.show {
  background-color: #00629B;

}

a.dropdown-item {
  color: white;
}

a.dropdown-item:hover, a.dropdown-item:focus {
  background-color: transparent;
  color: white;
}

.wrap-footer {


}

.wrap-contact {
  float: right;
  z-index: 1029;
}

.scrolling-navbar {
  height: 50px;
  padding: 0 10px 0 10px !important;
}

div.info-box {
  color: #31708f;
  background-color: #d9edf7;
  padding: 1em;
}

.highlight-border {
  border: 2px solid #ffcd00;
}

/*Start media queries*/
/* TODO: replace media queries with bootstrap options when possible */
@media(min-width: 538px) {
  *.wrap-utility {
    padding-bottom: 18px;
  }
  *.wrap-navbar {
    margin-bottom: 18px;
  }
  *.wrap-logo {
    float: right;
    margin: 0;
  }
  *.wrap-header {
    height: 110px;
  }

  /*
  body {
    margin-bottom: 120px !important;
  }*/
  /*
  *.wrap-contact {
    float: right;
    display: block;
  }*/
  *.scrolling-navbar {
    height: 120px;
    padding: 0 10px 0 100px !important;
  }
}
/*
@media(max-width: 538px) {
  .wrap-brand {
    display: none;
  }*/


#toTopButton {
  position: fixed;
  right: 0;
  font-size: 20px;
}

.stick-to-footer {
  bottom: 220px!important;
}

.btn.btn-swal-confirm-button {
  color: #fff;
  background: #00629B;
  font-family: "BrixSansRegular","Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 10px 30px;
  line-height: 1.5;
}

.btn.btn-swal-confirm-button:hover{
  background: #004766;
  color: #fff;
}

.btn.btn-swal-cancel-button {
  color: #fff;
  border: 0;
  background: initial;
  border-radius: 0.25em;
  background-color: #aaa;
  font-family: "BrixSansRegular","Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 10px 30px;
  line-height: 1.5;
  margin-left: 10px;
}

.btn.btn-swal-cancel-button:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
  color: #fff;
}

.btn.btn-swal-cancel-button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, .4);
}

/* Material updates */

div.mdc-form-field {
  font-weight: bold !important;
  color: #0055a6 !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mdc-form-field>label {
    margin-bottom: 0 !important;
}


/* If we need to do a transparent filled/underlined form field to keep it as it was this is the css */
/*.mdc-text-field--filled:not(.mdc-text-field--disabled) {*/
    /*background-color: black !important;*/
    /*background: none !important;
}*/

